import styled from 'styled-components/macro';

import { Flex, FooterSectionType, PartnersLogos } from 'common/components';
import { SkytradingLogo } from 'assets/SVGs';
import { FooterInfoWithMedia, FooterSection } from 'common/components/Footer/components';

type MobileFooterProps = {
  footerSections: FooterSectionType[];
};

export const MobileFooter = ({ footerSections }: MobileFooterProps) => (
  <Styled.Container>
    <PartnersLogos showTrustedLabel />
    <Flex direction="column" gap={32} align="flex-start">
      <Styled.InnerWrapper>
        <SkytradingLogo />
      </Styled.InnerWrapper>
      {footerSections.map((item) => (
        <FooterSection {...item} key={item.name} />
      ))}
      <FooterInfoWithMedia />
    </Flex>
  </Styled.Container>
);

const Styled = {
  Container: styled.footer`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 120px;
    padding: 24px 16px;
  `,
  InnerWrapper: styled.div`
    align-self: center;
  `,
};
